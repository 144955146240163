<template>
    <div>
        <!-- Table Container Card -->
        <b-card class="mb-0">
            
                <b-row>
                    <b-col cols="12" class="mt-1">
                        <EquipmentList />
                    </b-col>
                </b-row>
        </b-card>
    </div>
</template>
  
<script>
import EquipmentList from './List.vue'
import {
    BCard, BRow, BCol
} from 'bootstrap-vue'



export default {
    components: {
        BCard,
        BRow,
        BCol,
        EquipmentList

    }
}
</script>
