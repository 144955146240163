<template>
  <validation-observer ref="AddLabourForm" #default="{ invalid }">
    <b-form @submit.prevent="editEquipment">
      <b-row>
        <b-col md="6">
          <b-form-group label="Equipment Code" label-for="mc-first-name">
            <validation-provider #default="{ errors }" name="Equipment Code" vid="Equipment Code" rules="required">
              <b-form-input id="mc-first-name" placeholder="Equipment Code" v-model="equipment.assetCode" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Equipment Name" label-for="mc-last-name">
            <validation-provider #default="{ errors }" name="Equipment Name" vid="Equipment Name" rules="required">
              <b-form-input id="mc-last-name" placeholder="Equipment Name" v-model="equipment.name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Equipment Description" label-for="mc-city">
            <validation-provider #default="{ errors }" name="Equipment Description" vid="Equipment Description"
              rules="required">
              <b-form-textarea id="textarea-default" placeholder="Equipment Description" rows="3"
                v-model="equipment.description" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Purchase Date" label-for="mc-purchase">

            <b-form-datepicker id="example-purchase" class="mb-1" v-model="equipment.purchaseDate" />

          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Expiry Date" label-for="mc-expiry">

            <b-form-datepicker id="example-expiry" v-model="equipment.lifeEndDate" class="mb-1" />

          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Maintenance Date" label-for="mc-maintainance">


            <b-form-datepicker id="example-maintainance" v-model="equipment.maintainanceDate" class="mb-1" />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Location" label-for="mc-last-name">
            <validation-provider #default="{ errors }" name="Location" vid="Location" rules="required">
              <b-form-input id="mc-last-name" placeholder="Location" v-model="equipment.location" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Availability" label-for="mc-last-name">
            <b-form-select v-model="equipment.status" :options="options" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Cost Per Hour">
            <b-form-input type="number" placeholder="Cost / Hour (RS)" v-model="equipment.costPerHour" step="0.01" required />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col md="12" class="d-flex justify-content-between">
          <b-button type="submit" variant="primary" :disabled="invalid"><b-spinner small v-if="!loading"
              class="mx-2" />Submit</b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormGroup,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "AddEquipment",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    ///
    required,
  },
  props: {
    equipment: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      assetCode: "",
      purchaseDate: "",
      name: "",
      description: "",
      lifeEndDate: "",
      maintainanceDate: "",
      location: "",
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "1", text: "BOOKED" },
        { value: "2", text: "AVAILABLE" },
        { value: "3", text: "MAINTENANCE" },
        { value: "4", text: "BROKEN" },
        { value: "5", text: "NOT_AVAILABLE" },
      ],
    };
  },

  methods: {
    success() {
      this.$swal({
        title: "Equipment updated!",
        text: "You have successfully edited the equipment!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    ...mapGetters("equipmentModule", {
      loading: "loading",
    }),
    ...mapActions("equipmentModule", ["updateEquipmentAction"]),

    editEquipment() {
      let payload = {
        id: this.equipment.id,
        assetCode: this.equipment.assetCode,
        purchaseDate: this.equipment.purchaseDate,
        name: this.equipment.name,
        description: this.equipment.description,
        lifeEndDate: this.equipment.lifeEndDate,
        maintainanceDate: this.equipment.maintainanceDate,
        location: this.equipment.location,
        isActive: true,
        status: this.equipment.status,
        costPerHour: this.equipment.costPerHour,
      };
      this.updateEquipmentAction(payload)
        .then(() => {
          this.success();
          this.$emit("refresh");
          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.error();
          this.$emit("refresh");
          this.$bvModal.hide("modal-edit");
        });
    },
  },
};
</script>
