<template>
  <b-form @submit.prevent="addEquipment()">
    <b-row>
      <b-col md="6">
        <b-form-group label="Equipment Code" label-for="mc-first-name">
          <b-form-input id="mc-first-name" placeholder="Equipment Code" v-model="assetCode" required />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Equipment Name" label-for="mc-last-name">
          <b-form-input id="mc-last-name" placeholder="Equipment Name" v-model="name" required />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Equipment Description" label-for="mc-city">
          <b-form-textarea id="textarea-default" placeholder="Equipment Description" rows="3" v-model="description"
            required />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Purchase Date" label-for="mc-purchase">
          <b-form-datepicker id="example-purchase" class="mb-1" v-model="purchaseDate" required />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Expiry Date" label-for="mc-expiry">
          <b-form-datepicker id="example-expiry" v-model="lifeEndDate" class="mb-1" required />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Maintenance Date" label-for="mc-Maintenance">
          <b-form-datepicker id="example-Maintenance" v-model="maintainanceDate" class="mb-1" required />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Location" label-for="mc-last-name">
          <b-form-input id="mc-last-name" placeholder="Location" v-model="location" required />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Availability" label-for="mc-last-name">
          <b-form-select v-model="selected" :options="options" required />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Cost Per Hour">
          <b-form-input type="number" placeholder="Cost / Hour (RS)" v-model="costPerHour"  step="0.01" required />
        </b-form-group>
      </b-col>
      <!-- submit and reset -->
      <b-col md="12" class="d-flex justify-content-between">
        <b-button type="submit" variant="primary">Submit<b-spinner v-if="loading" class="mx-2" /></b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormGroup,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddEquipment",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BSpinner
  },
  data() {
    return {
      assetCode: "",
      name: "",
      description: "",
      purchaseDate: null,

      lifeEndDate: null,
      maintainanceDate: null,
      location: "",
      selected: null,
      costPerHour: 0,
      options: [
        { value: null, text: "Please select an option" },
        { value: 1, text: "BOOKED" },
        { value: 2, text: "AVAILABLE" },
        { value: 3, text: "MAINTENANCE" },
        { value: 4, text: "BROKEN" },
        { value: 5, text: "NOT_AVAILABLE" },
      ],
    };
  },
  computed: {
    ...mapGetters("equipmentModule", {
      loading: "loading",
    }),
  },
  methods: {
    success() {
      this.$swal({
        title: "Equipment!",
        text: "You have successfully added the equipment!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    ...mapActions("equipmentModule", ["addEquipmentAction"]),
    addEquipment() {
      let payload = {
        assetCode: this.assetCode,
        purchaseDate: this.purchaseDate,
        name: this.name,
        description: this.description,
        lifeEndDate: this.lifeEndDate,
        maintainanceDate: this.maintainanceDate,
        location: this.location,
        isActive: true,
        status: this.selected,
        costPerHour: this.costPerHour,
      };
      this.addEquipmentAction(payload)
        .then(() => {
          this.success();
          this.$emit("refresh")
          this.$bvModal.hide("modal-main");
        })
        .catch((error) => {
          
          this.error(error.response.data);
        });
    },
  },
};
</script>
