var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"AddLabourForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.editEquipment($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Equipment Code","label-for":"mc-first-name"}},[_c('validation-provider',{attrs:{"name":"Equipment Code","vid":"Equipment Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-first-name","placeholder":"Equipment Code"},model:{value:(_vm.equipment.assetCode),callback:function ($$v) {_vm.$set(_vm.equipment, "assetCode", $$v)},expression:"equipment.assetCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Equipment Name","label-for":"mc-last-name"}},[_c('validation-provider',{attrs:{"name":"Equipment Name","vid":"Equipment Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-last-name","placeholder":"Equipment Name"},model:{value:(_vm.equipment.name),callback:function ($$v) {_vm.$set(_vm.equipment, "name", $$v)},expression:"equipment.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Equipment Description","label-for":"mc-city"}},[_c('validation-provider',{attrs:{"name":"Equipment Description","vid":"Equipment Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Equipment Description","rows":"3"},model:{value:(_vm.equipment.description),callback:function ($$v) {_vm.$set(_vm.equipment, "description", $$v)},expression:"equipment.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Purchase Date","label-for":"mc-purchase"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-purchase"},model:{value:(_vm.equipment.purchaseDate),callback:function ($$v) {_vm.$set(_vm.equipment, "purchaseDate", $$v)},expression:"equipment.purchaseDate"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Expiry Date","label-for":"mc-expiry"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-expiry"},model:{value:(_vm.equipment.lifeEndDate),callback:function ($$v) {_vm.$set(_vm.equipment, "lifeEndDate", $$v)},expression:"equipment.lifeEndDate"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Maintenance Date","label-for":"mc-maintainance"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-maintainance"},model:{value:(_vm.equipment.maintainanceDate),callback:function ($$v) {_vm.$set(_vm.equipment, "maintainanceDate", $$v)},expression:"equipment.maintainanceDate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location","label-for":"mc-last-name"}},[_c('validation-provider',{attrs:{"name":"Location","vid":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-last-name","placeholder":"Location"},model:{value:(_vm.equipment.location),callback:function ($$v) {_vm.$set(_vm.equipment, "location", $$v)},expression:"equipment.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Availability","label-for":"mc-last-name"}},[_c('b-form-select',{attrs:{"options":_vm.options},model:{value:(_vm.equipment.status),callback:function ($$v) {_vm.$set(_vm.equipment, "status", $$v)},expression:"equipment.status"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cost Per Hour"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Cost / Hour (RS)","step":"0.01","required":""},model:{value:(_vm.equipment.costPerHour),callback:function ($$v) {_vm.$set(_vm.equipment, "costPerHour", $$v)},expression:"equipment.costPerHour"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-between",attrs:{"md":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid}},[(!_vm.loading)?_c('b-spinner',{staticClass:"mx-2",attrs:{"small":""}}):_vm._e(),_vm._v("Submit")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }